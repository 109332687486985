export default class FileUpload {

    private dragging: boolean = false;
    private uploading: boolean = false;
    private leaveTimer?: number;


    constructor(
        private dragArea: HTMLElement,
        private readonly form: HTMLFormElement,
        private fileField: HTMLInputElement
    ) {
        this.attachEventListeners();
    }


    private attachEventListeners(): void {
        const dragEvents = ["dragover", "dragenter", "dragleave", "drop"];
        dragEvents.forEach(eventType => {
            this.dragArea.addEventListener(eventType, this.handleDrag.bind(this), false);
        });

        this.fileField.addEventListener('change', (event) => this.handleChange(event), false);
        this.form.addEventListener("submit", (event) => this.handleSubmit(event), false);

        this.dragArea.querySelector('#label_upload').addEventListener('click', this.handleClick.bind(this), false);

        document.getElementById('upload_here')?.addEventListener('click', this.handleClick.bind(this), false);

    }

    private handleDrag(event: DragEvent): void {
        switch (event.type) {
            case "dragover":
            case "dragenter":
                if (this.uploading || !event.dataTransfer?.types.includes("Files")) return;
                clearTimeout(this.leaveTimer);
                event.preventDefault();
                this.showDragView();
                break;
            case "dragleave":
                if (this.uploading) return;
                this.leaveTimer = window.setTimeout(() => {
                    if (!this.dragArea.contains(event.relatedTarget as Node)) {
                        this.removeDragView();
                    }
                }, 50);
                break;
            case "drop":
                if (this.uploading) return;
                event.preventDefault();
                const file = event.dataTransfer?.files[0];
                if (file) {
                    this.handleFileUpload(file);
                }
                break;
        }
    }


    private handleChange(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.handleFileUpload(input.files[0]); // Directly handle the file upload
        }
    }


    private handleSubmit(event: SubmitEvent): void {
        event.preventDefault();
        this.submitForm();
    }


    private handleClick(event: MouseEvent): void {
        event.preventDefault();
        if (this.uploading) return;
        event.stopPropagation();
        this.fileField.click();
    }


    private showDragView(): void {
        if (this.dragging) return;
        this.dragging = true;
        this.dragArea.classList.add('dragging');
    }


    public removeDragView(): void {
        if (!this.dragging) return;
        this.dragging = false;
        this.dragArea.classList.remove('dragging');
    }


    private handleFileUpload(file: File): void {
        this.removeDragView();

        const allowedAudioExtensions = ['.caf'];
        const fileExtension = file.name.slice((file.name.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();

        if (!file.type.startsWith('audio/') && !allowedAudioExtensions.includes(`.${fileExtension}`)) {
            this.dispatchDropErrorEvent('Only audio files are allowed. File type ' + file.type + ' is not supported.');
            return;
        }

        let size_mb = Math.ceil(file.size / Math.pow(1024, 2));
        if (size_mb > 60) {
            this.dispatchDropErrorEvent(`Your audio file is ${size_mb}MB. Max allowed audio file size is 60MB.`);
            return;
        }

        this.uploadFile(file);
    }


    private dispatchDropErrorEvent(message: string): void {
        this.uploading = false;
        this.form.reset();
        document.dispatchEvent(new CustomEvent('audioUploadError', {detail: {message}}));
    }


    private async uploadFile(file: File): Promise<void> {
        this.uploading = true;
        this.removeDragView();
        this.dispatchUploadProgressEvent('Uploading audio');

        const formData = new FormData();
        formData.append('uploaded_audio_file', file);

        try {
            const response = await fetch('/upload/file', {
                method: "POST",
                body: formData,
            });

            if (!response.ok) throw await response.json();

            const json = await response.json();
            this.dispatchUploadSuccessEvent(json.redirect_url);
        } catch (error: any) {
            this.uploading = false;
            this.dispatchDropErrorEvent(error.message ?? 'Something went wrong, please contact us.');
        }
    }

    private dispatchUploadProgressEvent(message: string): void {
        this.uploading = true;
        document.dispatchEvent(new CustomEvent('audioUploadProgress', {detail: {message}}));
    }

    private dispatchUploadSuccessEvent(redirect: string): void {
        this.uploading = false;
        document.dispatchEvent(new CustomEvent('audioUploadSuccess', {detail: {redirect}}));
    }

    public submitForm(): void {
        const file = this.fileField.files?.[0];
        if (file) {
            this.handleFileUpload(file);
        } else {
            this.dispatchDropErrorEvent('Please select a file before submitting.');
        }
    }

}
